import { Box, Button, TextField } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import { SettingsContext, anyIO } from '../../system/settings';
import { typeBaseData, baseData } from './post';
import DialogArticlesPost from '../dialog/input_articles_post';
import Header from '../header';

const name = 'articles_put';

export default function ArticlesPut(){
    const navigate = useNavigate();
    const {settings,mode,authkey} = React.useContext(SettingsContext);
    const setting = settings.filter((one)=>{
        return one.mode === mode
    })[0];
    const [result,setResult] = React.useState<string>('');
    const [target,setTarget] = React.useState<string>('');
    const [open,setOpen] = React.useState<boolean>(false);
    const [data,setData] = React.useState<typeBaseData>(baseData);
    const [clipping_task_id,set_clipping_task_id] = React.useState<string>('')
    const [article_id,set_article_id] = React.useState<string>('')
    const handleLink = (page:string) => {
        navigate(page)
    }
    const handleRequest = async () => {
        setResult('requesting');
        const url = `${setting.url}/articles/${clipping_task_id}/${article_id}`
        console.log('url',url);
        console.log('request body',data);
        console.log(`"Auth-Fuerte" header value is "${authkey}"`);
        try{
            const response = await fetch(url, {
                method:'PUT',
                mode:'cors',
                headers:{
                    'Content-Type':'application/json',
                    'Auth-Fuerte':authkey
                },
                body:JSON.stringify(data)
            });
            if (response.ok) {
                const responseData = await response.json(); // レスポンスをJSONとして解析
                console.log('response',responseData)
                if(responseData.err_code === 0){
                    setResult('success');
                }else if(responseData.err_code === 401){
                    setResult('auth error');
                }else{
                    setResult(`error code: ${responseData.err_code}`);
                }
            }else{
                const responseData = await response.json(); // レスポンスをJSONとして解析
                console.log('response(error)',responseData)
                // console.log('response(error)',response)
                setResult('http error')
                // throw new Error('ネットワークエラー');
            }
        }catch(error){
            console.log('エラー',error)
        }finally{

        }
    }
    const handleEdit = (input:string) => {
        setTarget(input);
        setOpen(true);
    }
    const handleChangeArticleId = (e:any) => {
        const temp = e.target.value.replace(/\D/g,"");
        const newValue = (temp==='')?'':`${Number(temp)}`
        set_article_id(newValue)
    }
    React.useEffect(()=>{
        const str = anyIO.get(name);
        setData((str==='')?baseData:JSON.parse(str))
        const temp_clipping_task_id = anyIO.get(`${name}_clipping_task_id`);
        set_clipping_task_id(temp_clipping_task_id)
        const temp_article_id = anyIO.get(`${name}_article_id`);
        set_article_id(temp_article_id)
    },[]);
    React.useEffect(()=>{
        const str = JSON.stringify(data);
        anyIO.set(name,str);
        anyIO.set(`${name}_clipping_task_id`,clipping_task_id);
        anyIO.set(`${name}_article_id`,article_id);
    },[data,clipping_task_id,article_id]);
    return(
        <React.Fragment>
            <Box sx={{margin:'10px'}}>
                <Header />
                <Box>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="medium" color="error" onClick={()=>{handleLink('/')}}>
                        TOPへ戻る
                    </Button>
                    <Button sx={{textTransform:'none',marginLeft:'10px'}} variant="outlined" size="medium" color="error" onClick={()=>{handleLink('/articles_menu')}}>
                        {`/articles API 一覧へ戻る`}
                    </Button>
                </Box>
                <hr />
                <Box>
                    PUT /articles/{`<clipping_task_id>/<article_id>`}&emsp;
                    <Button sx={{textTransform:'none'}} variant="contained" size="large" onClick={handleRequest}>
                        リクエスト送信
                    </Button>&emsp;
                    <span style={{fontWeight:'bold',fontSize:'1.1em',color:'red'}}>{result}</span>
                    <hr />
                    <Box sx={{marginBottom:'20px',width:'50%'}}>
                        &lt; clipping_task_id &gt;
                        <TextField
                            fullWidth
                            value={clipping_task_id}
                            onChange={(e)=>{set_clipping_task_id(e.target.value)}}
                        />
                        &lt; article_id &gt;
                        <TextField
                            fullWidth
                            value={article_id}
                            onChange={handleChangeArticleId}
                        />
                    </Box>
                    &lt; Request body &gt;
                    <Box sx={{margin:'10px',backgroundColor:'#f5caa4',borderRadius:'10px',padding:'5px',fontSize:'1.1em',lineHeight:'2.0em'}}>
                    {'{'}<br />
                    {/* &emsp;{'"client_id":"'}{data.client_id}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('client_id')}}>編集</Button><br /> */}
                    &emsp;{'"title":"'}{data.title}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('title')}}>編集</Button><br />
                    &emsp;{'"url":"'}{data.url}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('url')}}>編集</Button><br />
                    &emsp;{'"news_date":"'}{data.news_date}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('news_date')}}>編集</Button><br />
                    &emsp;{'"media":{'}<br />
                    &emsp;&emsp;{'"code":"'}{data.media.code}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('media.code')}}>編集</Button><br />
                    &emsp;&emsp;{'"name":"'}{data.media.name}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('media.name')}}>編集</Button><br />
                    &emsp;&emsp;{'"url":"'}{data.media.url}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('media.url')}}>編集</Button><br />
                    &emsp;&emsp;{'"category":{'}<br />
                    &emsp;&emsp;&emsp;{'"id":"'}{data.media.category.id}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('media.category.id')}}>編集</Button><br />
                    &emsp;&emsp;&emsp;{'"name":"'}{data.media.category.name}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('media.category.name')}}>編集</Button><br />
                    &emsp;&emsp;{'}'}<br />
                    &emsp;{'}'}<br />
                    {'}'}
                    </Box>
                </Box>
            </Box>
            <DialogArticlesPost target={target} open={open} setOpen={setOpen} data={data} setData={setData} />
        </React.Fragment>
    )
}
